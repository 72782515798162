.dashboard-container {
    margin: 0px 0px 20px 0px;
}

.dashboard-main-container {
    margin-bottom: 40px;
}


.dashboard-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.main-upper-left-div {
    display: flex;
    align-items: center;
    gap: 20px;
    background: linear-gradient(317deg, rgba(119, 55, 255, 1) 0%, rgba(75, 15, 201, 1) 0%, rgba(65, 65, 65, 1) 67%);
    margin-left: 40px;
    border-radius: 30px;
    margin-top: 20px;
    width: 370px;
}

.user-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    border-radius: 9999px;
    background: linear-gradient(65deg, #4e018c 10%, #f2c5d1 50%, #027caa 70%);
    /* border: linear-gradient(65deg,#f5094c 10%,#f2c5d1 50%,#027caa 70%); */
}

.user-img-box img {
    width: 100px;
    height: 100px;
    border-radius: 9999px;
    background-color: #363737;
}


.main-upper-right-div {
    width: 400px;
    padding: 30px 0px;
    color: white;
    /* background-color: #242526; */
    background: linear-gradient(317deg, rgba(119, 55, 255, 1) 0%, rgba(75, 15, 201, 1) 0%, rgba(65, 65, 65, 1) 67%);
    margin-right: 40px;
    border-radius: 30px;
}

.right-inner-row {
    padding: 15px 20px;
    background-color: #282d41;
    border-radius: 15px;
}

.right-inner-row .right-inner-flex-box {
    gap: 80px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: ; */
}


.copy-button {
    background-color: #406AFF;
    border-radius: 20px;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    color: white;
}

.copy-button:hover {
    transition: all 150ms ease-in-out smooth;
    background-color: #2992b8;
}


@media (max-width:768px) {

    .dashboard-inner-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .see-more {
        display: none;
    }

    .see-more.visible {
        width: 107px;
        display: block;
    }

    .main-upper-right-div {
        width: 100%;
    }


}


@media (max-width:428px) {

    /* .user-img-box {
        width: 125px;
        height: 80px;
    }
       .user-img-box img{
        width: 70px ;
        height: 70px;
    } */


    .right-inner-row .right-inner-flex-box {
        gap: 10px !important;
    }
}

@media (max-width:468px) {

    .platform-left-box {
        /* width: 410px !important; */
        overflow-x: scroll !important;
        overflow-y: hidden;
    }

    .table-in-row-1 {
        width: 400px;
    }

    .platform-left-box::-webkit-scrollbar {
        border: transparent !important;
    }

    .platform-left-box::-webkit-scrollbar-track {
        background-color: transparent !important;
        /* color of the track */
    }

    .platform-left-box::-webkit-scrollbar-thumb {
        background-color: transparent !important;
    }
}

.see-more-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 6px;
    color: #9FA4B5;
    background-color: #242526;
    font-weight: 700;
    width: 80%;
    border-radius: 20px;
    border: transparent;
    padding: 7px 40px;
}



.first-container-box-left {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #000000;
    width: 270px !important;
    border-radius: 18px;
    padding: 20px 20px;

}
.zero-number{
    color:"white !important"
}

.first-container-box-left .reload-icon img {
    width: 20px;
}

.zero-number {
    font-size: 18px;
    font-weight: 600;
}

.first-container-box-right b,
.first-container-box-left b {
    color: #ffffff;
}


.icon-redius {
    /* margin: 10px 0px 0px; */
    margin-top: 20px;
    border-radius: 20px;
    padding: 10px 15px;
    background-color: #3A3B3C;
}

.first-container-box-right {
    border: 1px solid black;
    border-radius: 18px;
    padding: 20px 20px;
    color: white;
    background-color: #242526;
}


.right-box-lower {
    margin-top: 20px;
}

.dashboard-left-box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
}


.dashboard-right-box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
}

.platform-heading {
    margin: 30px 0px;
    font-weight: 700;
    font-size: 34px;
}


.platform-container-main {
    /* color: white; */
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 30px;

}


@media (max-width:700px) {
    .platform-container-main {
        width: 100%;
        flex-direction: column-reverse;
    }

    .platform-left-container {
        width: 100%;

    }

    .platform-right-container {
        width: 100%;
        /* height: 100vh; */
    }

    .platform-right-container .platform-right-box-1,
    .platform-right-box-2,
    .platform-right-box-3 {
        width: 287%;
    }

    .platform-left-container .platform-left-box {
        /* width: 154%; */
        overflow-x: scroll;

    }
}


.announsment {
    font-size: 17px;
    color: #abaeb1;
    width: 98%;
    padding: 20px 20px 30px 20px;
    height: 10vh;
    background-color: #242526;
    border-radius: 15px;
    margin: auto;
    box-shadow: 0px 0px 20px -6px gray;
}



.Announcment-text {
    width: 200px;
}

/* .hurryup {
        background: linear-gradient(45deg, #ff7e5f, #feb47b);
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block;
      } */


.platform-left-container {
    width: 100%;

}

.platform-right-container {
    width: 35%;
    /* height: 100vh; */
}


.platform-left-container .platform-left-box {
    padding: 15px 20px;
    border-radius: 15px;
    background-color: #242526;
    box-shadow: 0px 0px 20px -6px gray;
    height: 169vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}


.platform-left-container .platform-left-box {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 20px;
    /* height: fit-content; */
}

.platform-left-container .platform-left-box::-webkit-scrollbar {
    border: transparent;
}

.platform-left-container .platform-left-box::-webkit-scrollbar-track {
    background-color: transparent;
    /* color of the track */
}

.platform-left-container .platform-left-box::-webkit-scrollbar-thumb {
    background-color: transparent;
}


.table-in-row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.see-more-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.see-more-button {
    color: white;
    background-color: gray;
    /* border: 1px solid red; */
    display: flex;
    gap: 9px;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    padding: 10px 70px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 700;

    cursor: pointer;
}

.table-in-row-1 .table-left-div,
.table-right-div {
    align-items: center;
    display: flex;
    gap: 14px;
}


.NewUser {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.transaction {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 15px;
}

.empty-div-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.empty-main-div {
    margin: 10px 0px;
    /* gap: 5px; */
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.empty-main-div .empty-row-1-div {
    display: flex;
    align-items: center;
    gap: 5px;
    /* margin: 5px; */
    width: 150px;
    flex-wrap: wrap !important;
}

.empty-main-div .empty-row-1-div .empty-div {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #483D3F;
}


.empty-div-1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(244, 84, 84) !important;
}

.empty-div-2-1 {
    background-color: rgb(67, 112, 210) !important;

}


.table-right-div,
.right-box-3-data-div-left {
    color: hsla(0, 0%, 100%, .5);
}

.table-in-row-1 {
    padding: 15px 0px;
    border-bottom: 1px solid #363737;
}


.table-left-div .table-user-icon {
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3A3B3C;
    width: 35px;
    height: 35px;
}

/* span {
    color: hsla(0, 0%, 100%, .5);

} */

.new-user-heading span,
.right-box-1-heading,
.right-box-3-heading-main {
    color: hsla(0, 0%, 100%, .5);
    font-weight: 500;
    /* font-size: 15px; */
}


.ID-box {
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #272C3C;
    color: #406AFF;
    cursor: pointer;
}

.ID-box:hover {
    background-color: rgba(64, 106, 255, .3);
    transition: all 150ms ease-in-out;
}


.platform-right-container {
    gap: 39px;
    display: flex;
    flex-direction: column;
}


.myincome-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.myincome-container-1 {
    display: flex;
    margin-left: 35px;

}

.dashboard-container-2 {
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 30px !important;
}

.dashboard-container {
    flex-wrap: wrap !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap:14px !important;
}


.active-button {
    padding: 4px 10px;
    background: transparent;
    color: #8FA1B1;
    border-radius: 15px !important;
    border: 1px solid;
    border-image: linear-gradient(to right, #8FA1B1, #FF5E5E); 
    border-image-slice: 1;

}


.myincome-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 42%;
    height: 230px;
    text-align: center;
    box-shadow: 0px 0px 20px -6px gray;
    border-radius: 15px;
    padding: 90px 50px;
    background-color: #242526;
}


.dashboard-main-box {
    margin-top: 40px;


}

.dashboard-container-box {
    cursor: pointer;
    width: 190px;
    height: 150px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: 0px 0px 20px -6px gray;
    border-radius: 15px;
    /* padding: 35px ; */
    background-color: #242526;

}

.dashboard-container-box span {
    font-size: 20px;
    color: white;
    font-size: 500;
}

.myincome-box span {
    font-size: 30px;
    color: white;
    font-size: 500;
}

.myincome-box h3 {
    /* font-size: 25px !important; */

}

/* @media (max-width:440px) {

    .platform-left-container .platform-left-box {
height: 136vh;
    }
} */


.dashboard-boxes {
    background: rgb(119, 55, 255);
    background: linear-gradient(317deg, rgba(119, 55, 255, 1) 0%, rgba(75, 15, 201, 1) 0%, rgba(65, 65, 65, 1) 67%);
}

.dashboard-boxes-1 {
    background: rgb(119, 55, 255);
    background: linear-gradient(317deg, rgba(119, 55, 255, 1) 0%, rgba(75, 15, 201, 1) 0%, rgba(65, 65, 65, 1) 67%);
}

.my-income-box-1 {
    background: rgb(74, 134, 93);
    background: linear-gradient(29deg, rgba(74, 134, 93, 1) 31%, rgba(26, 181, 21, 1) 58%) !important;
}

.my-income-box-2 {

    background: rgb(134, 133, 74);
    background: linear-gradient(29deg, rgba(134, 133, 74, 1) 31%, rgba(175, 181, 21, 1) 58%) !important;
}

.my-income-box-3 {

    background: rgb(74, 130, 134);
    background: linear-gradient(29deg, rgba(74, 130, 134, 1) 28%, rgba(21, 38, 181, 1) 58%) !important;
}


.my-income-box-4 {
    background: rgb(205, 164, 160);
    background: linear-gradient(29deg, rgba(205, 164, 160, 1) 8%, rgba(240, 9, 9, 1) 100%) !important;
}

.my-income-box-5 {
    background: rgb(97, 93, 93);
    background: linear-gradient(29deg, rgba(97, 93, 93, 1) 8%, rgba(0, 0, 0, 1) 100%) !important;
}

@media (max-width:768px) {

    .platform-left-container .platform-left-box {
        height: 106vh;
    }

    .myincome-box {
        width: 90%;
    }

    .myincome-container,
    .myincome-container-1 {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .myincome-container-1 {
        margin: auto;
    }
}


/* --------package slot container css----------- */

.inner-package-slot-container {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    /* margin: auto; */

}

@media (max-width:406px) {
    .right-inner-row .right-inner-flex-box {

        gap: 9px !important;
        flex-direction: column;
    }

    .right-inner-row {
        align-items: center;
        text-align: center;
    }
}

@media (max-width:768px) {
    .announsment {
        width: 95%;
    }

    .right-inner-row .right-inner-flex-box {
        gap: 25px;
    }

    .dashboard-container {
        width: 100%;
        gap: 20px !important;
        /* flex-wrap: nowrap !important; */
    }

    .inner-package-slot-container {
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .dashboard-boxes {
        width: 550px;
        height: 240px;
    }

    .inner-package-slot-container .inner-left-package-container {
        width: 550px !important;
        height: 240px;
        border-radius: 15px;
        /* background-color: #3A3B3C; */
    }
}


.inner-package-slot-container .inner-left-package-container,
.inner-right-slot-container {
    cursor: pointer;
    width: 430px;
    height: 230px;
    border-radius: 15px;
    /* background-color: #3A3B3C; */
}



.inner-left-package-container {
    /* background: url(../../../../public/images/red-blur.png) no-repeat; */
    background-position: left center;
    background-size: cover;
    background-color: #302c2c;
    padding: 20px 30px;

}

.inner-right-slot-container {
    /* background: url(../../../../public/images/purple-blur.png) no-repeat !important; */
    background-position: left center !important;
    background-size: cover !important;
    /* background-color: #1e1e1e !important; */
    background-color: #302c2c !important;
}

.inner-left-package-container .package-first-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 20px 0px;
}

.empty-right-div {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
}

.empty-right-div-box-2 {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    height: 120px;
}

.preview-button button {
    padding: 10px 45px;
    font-size: 18px;
    color: white;
    background-color: #E1444D;
    border-radius: 10px;
    border: transparent;
    font-weight: 800;
}

.preview-button-right-box button {
    padding: 10px 45px;
    font-size: 18px;
    color: white;
    background-color: #7737FF;
    border-radius: 10px;
    border: transparent;
    font-weight: 800;
}

.package-header {
    color: white;
    font-size: 20px;
    font-weight: 700;
}


.platform-right-container .platform-right-box-1,
.platform-right-box-2,
.platform-right-box-3 {
    box-shadow: 0px 0px 20px -6px gray;
    gap: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 20px 0px 20px 20px;
    background-color: #242526;
}

.right-box-1-heading {
    font-size: 17px;
    font-weight: 400;
}

.right-box-3-heading-main {
    display: flex;
    font-size: 17px;
    font-weight: 400;
    border-bottom: 1px solid #363737;
    padding-bottom: 10px !important;
}

.platform-right-box-3 .right-box-3-data-div {
    border-bottom: 1px solid #363737;
    padding: 0px 10px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.platform-right-box-3 .right-box-3-data-div .right-box-3-data-div-right {
    color: white;
    display: flex;
    gap: 10px;
}


.dashboard-container-1 {
    margin-bottom: 30px;
    /* display: flex; */
    /* justify-content: space-between; */
}



@media (max-width:700px) {
    .dashboard-container-1 {
        flex-direction: column;
        gap: 15px;

    }

    .dashboard-left-box {
        width: 100%;
        flex-direction: column;
        gap: 10px;

    }

    .dashboard-right-box {
        width: 100%;
        flex-direction: column;
        gap: 10px;

    }

    .first-container-box-left {
        width: 250px !important;
        /* height: 230px; */
    }
}

.card-title h4 {
    color: #999A9D !important;
}


body.dark-only .page-wrapper .page-body-wrapper .page-body .card:not(.email-body).widget-1 {
    cursor: pointer;
}

.widget-1 .f-w-500 {
    display: none !important;
}

@media (max-width: 1600px) {
    .widget-1 .widget-content {
        width: 100%;
        gap: 10px;
        justify-content: space-between;
        display: flex;
    }
}

.widget-1 .widget-content h4,
.widget-1 .widget-content .h4 {
    color: white !important;
    margin-bottom: 4px;
}


.login-card .login-main .theme-form label{
    color: #8B9FA8;
}

.body.dark-only .login-card .login-main{
    box-shadow: 0px 0px 20px -6px gray !important;
}