.download-pdf-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 30px;
}

.download-pdf-button {
    background-color: #007bff;
    /* Button background color */
    color: #fff;
    /* Button text color */
    padding: 10px 15px;
    /* Adjust padding as needed */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}


@media (max-width:430px){
    .download-pdf-container{
        justify-content: center;
        align-items: center;
    }
} 