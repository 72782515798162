body {
    background-color: black !important;
}

.Need-help-container {
    margin: 40px 0px 70px 0px;
    text-align: center;
}

.Need-help-container h1 {
        color: white;
        margin-bottom: 20px;
}

.Need-help-container p {
    margin-bottom: 20px;
    color: gray;
}

.Need-help-container button {
    color: white;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    padding: 5px 0px 15px 0px;
    border: 1px solid blue;
    transition: all 250ms ease-in-out;
}

.Need-help-container button:hover {
    color: white;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgb(0, 255, 115);
    box-shadow: 0px 0px 20px -6px goldenrod;
    /* background-color: aqua; */
}