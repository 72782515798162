.App {
    text-align: center;
  }
  .top-bar {
    width: 100%;
    height: 5vh;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    z-index: 500;
    margin: 30px 0px 20px 0px;
  }
  
  .top-bar-left {
    /* display: flex; */
    /* align-items: center; */
  }
  
  .top-bar-email,
  .top-bar-contact,
  .top-bar-skype {
    margin-right: 20px;
    transition: all 0.5s ease;
  }
  
  .top-bar-email i,
  .top-bar-contact i,
  .top-bar-skype i {
    color: #ffffff;
    padding-top: 3px;
    margin-right: 6px;
    transition: all 0.5s ease;
  }
  
  .top-bar-email:hover i,
  .top-bar-contact:hover i {
    color: #f1552c;
  }
  
  .top-bar-email:hover a,
  .top-bar-contact:hover a {
    color: #f1552c;
  }
  
  .top-bar-email a,
  .top-bar-contact a,
  .top-bar-skype a {
    color: #ffffff;
    letter-spacing: .5px;
    text-decoration: none;
    transition: all 0.5s ease;
  }
  
  .top-bar-right a {
    color: #fff;
    margin: 0px 12px;
    transition: all 0.5s ease;
  }
  
  @media query {}
  
  .top-bar-right a:hover {
    color: #f1552c;
  }
  @media only screen and (min-width: 300px) and (max-width: 404px) {
    .top-bar {
      height: auto;
      flex-wrap: wrap-reverse;
      display: flex;
      justify-content: center;
    }
  
    .top-bar-left {
      flex-wrap: wrap;
      display: block;
      margin: auto;
    }
    .top-bar-left div:first-child,
    .top-bar-left div:nth-child(2),
    .top-bar-right {
      display: block;
      margin-bottom: 4px;
    }
  
    .top-bar-right {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }
  }
  
  @media only screen and (min-width: 404px) and (max-width: 500px) {
    .top-bar {
      height: auto;
      flex-wrap: wrap-reverse;
      display: flex;
      justify-content: center;
    }
  
    .top-bar-contact {
      margin-right: 0px;
      /* display: none; */
    }
  
    .top-bar-left {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
    }
  
    .top-bar-right {
      display: block;
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 560px) {
    .top-bar {
      height: auto;
      flex-wrap: wrap-reverse;
      display: flex;
      justify-content: center;
    }
  
    .top-bar-contact {
      margin-right: 0px;
    }
  
    .top-bar-left {
      flex-wrap: wrap;
      display: block;
      margin: auto;
    }
  }