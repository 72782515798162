.sidebar {
    width: 60px;
    height: 100%;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
  }
  
  .sidebar.open {
    width: 200px;
  }
  
  .toggle-btn {
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: #333;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .sidebar-content {
    padding-top: 50px; /* Adjust according to your need */
    margin-top: 120px;
  }
  
  .sidebar-btn {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 20px;
    color: #fff;
    background-color: #444;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-btn:hover {
    background-color: #555;
  }