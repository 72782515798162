.box, .box-small {
    background: rgba(8, 8, 36, 0.7);
    border-radius: 30px;
    border: 1px solid #0d6efd;
    box-shadow: 0 0 50px 5px rgb(39 219 211 / 14%);
    max-width: 1170px;
    padding: 1.875rem;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 992px){
.template-onepage section.page-frontpage {
    margin-top: 5rem;
    margin-bottom: 1.875rem;
}
}


.border-color-custom{
    font-size: 15px !important;
    font-weight: 600 !important;
    letter-spacing: .5px !important;
    padding: 0.9375rem 2.1875rem !important;
    border-radius: 3.375rem !important;
}
.border-color-custom:hover{
    background: linear-gradient(0deg, rgba(241, 85, 44, 0.5) 0%, transparent 100%) !important;
}

.border-color-custom-1{
    font-size: 15px !important;
    font-weight: 600 !important;
    letter-spacing: .5px !important;
    padding: 0.5375rem 2.0875rem !important;
    border: 1px solid lightblue !important;
    border-radius: 05.375rem !important;
}
.border-color-custom-1:hover{
    background: linear-gradient(0deg, rgba(44, 241, 231, 0.5) 0%, transparent 100%) !important;
}


/* 
.btn-primary {
    background: transparent;
    border: 3px solid #f1552c;
}
/* .btn, .search-form .search-submit {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .5px;
    padding: 0.9375rem 2.1875rem;
    border-radius: 3.375rem;
} */
/* .btn, .search-form .search-submit:hover {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */ 