



.center-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh !important;
}
