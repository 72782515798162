.timeline{
            position: relative;
            margin: 100px 100px;
            z-index: 0;
            font-family: 'poppins,sans-serif';

        }
        .container_timeline{
            padding: 10px 50px;
            position: relative;
            width: 50%;
            animation: movedown 1s linear forwards;
            opacity: 0;
            /* background-color: red; */
        }
        @keyframes movedown {
          0%{
            opacity: 1;
            transform: translateY(-50px);
          }  
          100%{
            opacity: 1;
            transform: translateY(0px);
          }  
        }
        .container_timeline:nth-child(1){animation-delay: 0s;}
        .container_timeline:nth-child(2){animation-delay: 1s;}
        .container_timeline:nth-child(3){animation-delay: 2s;}
        .container_timeline:nth-child(4){animation-delay: 3s;}
        .container_timeline:nth-child(5){animation-delay: 4s;}
        .container_timeline:nth-child(6){animation-delay: 5s;}

        .img_timeline{
            width: 40px;
            position: absolute;
            top: 17%;
            left: 100%;
            transform: translate(-50%);
            border-radius: 50%;
            z-index: 1;
        }
        .textbox{
            padding: 20px 30px;
            background: #fff;
            border-radius:6px ;
            font-size: 15px;
            position: relative;

        }
        .textbox h1{
            font-size: 20px;
            font-weight: 600;
            /* letter-spacing: 0.7px; */
        }
        small{
            display: inline-block;
            margin-bottom: 10px;
            font-size: 15px;
        }

        .left-container_timeline{
            left: 0;
        }
        .right-container_timeline{
            left: 50%;
        }
        .right-container_timeline img{
            left: 0;
        }
        .timeline::after{
            content: '';
            position: absolute;
            width: 6px;
            height: 100%;
            background: #fff;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            animation: line 6s linear forwards;
        }
        @keyframes line {
            0%{
                height: 0;
            }
            100%{
                height: 100%;
            }
        }
        .right-container_timeline::after{
            left: 0%;
        }
        .left-arrow{
           width: 0px;
           height: 0px;
           position: absolute;
           top: 15%;
            left: 100%;
            z-index: 1;
            
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid #fff;
        }
        .right-arrow{
            width: 0px;
           height: 0px;
           position: absolute;
           top: 15%;
            right: 100%;
            z-index: 1;
            
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-right: 15px solid #fff;
        }
        @media screen and (max-width: 722px) {
            .timeline{
                margin: 50px auto;
            }
            .timeline::after{
                left: 30px;
            }
            .container_timeline{
                width: 100%;
                padding-left: 80px;
                padding-right: 25px;
            }
            .textbox{
                font-size: 13px;
            }
            .textbox small{
                margin-bottom: 10px;
            }
            .right-container_timeline{
                left: 0;
            }
            .left-container_timeline img, .right-container_timeline img{
                left: 30px;
            }
            .right-arrow,.left-arrow{
                border-right: 15px solid #fff;
                border-left: 0;
                left: -15px;
            }
        }