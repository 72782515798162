body {
  margin: 0% !important;
  padding: 0% !important;
}

.navbar-header-container {
  /* padding: 10px 20px; */
  top: 0%;
  background-color: #1a1a1a;
  /* Set your desired background color */
  position: fixed;
  /* Make the navbar fixed */
  width: 100%;
  z-index: 1000;
  margin-bottom: 40px !important;
}


.navbars {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px -6px gray;
  padding: 0px 30px;
}

.navbars .Navbar-left-header img {
  /* font-size: 24px ;
  font-weight: 800;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  letter-spacing: 2px; */
  width: 160px;
  height: 90px;
}

@media (max-width:430px) {
  .navbars {
    padding: 0px 10px !important;
  }

  .navbars .Navbar-left-header img {
    width: 130px;
    height: 70px;
  }

}